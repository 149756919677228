import React from "react";
import './Banner.css'

const Banner = () => {
    return(
        <div id="home">
             <div className="banner">
               <h2>We Provide <br/><span>Best</span> Cloud <span>Solutions</span>  <br/>
                 & <br/>Managed Services</h2>
            </div>
        </div>
    );

}

export default Banner;