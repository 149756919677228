import React, { Component , useState} from 'react'
import './ButtonPermanant.css'
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
 const ButtonPermanant = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

 const handleButtonClick = () => {
   setSidebarOpen(!isSidebarOpen);
 };

 const handleSidebarClose = () => {
   setSidebarOpen(false);
 };


    return (
        <div className="mybutton"  >
        {!isSidebarOpen && (
            <button className="feedback" onClick={handleButtonClick}>&gt;&gt;</button>
        )}
        <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarClose} />
        </div>
    )
  
}

export default ButtonPermanant
