import React, { Component } from 'react'
import './Teams.css'
import background1 from "../../assets/1.jpg";
import background2 from "../../assets/2.jpg";
import background3 from "../../assets/3.jpg";


const Teams = () =>{
 
    return (
    <section className="sec secTeams" >
    <div className="content">
      <div className="mxw800p" id="about">
        <h3 className="TeamsTitle">Meet Our Expert</h3>
        <p className="TeamsContent">Meet our team of cloud experts, passionate about delivering exceptional solutions for your cloud project. With their deep knowledge and experience in cloud technologies, they are equipped to understand your unique requirements and provide expert guidance. From designing robust architectures to implementing scalable solutions, our experts will ensure a seamless and successful cloud journey for your business. Trust in their expertise to leverage the full potential of cloud computing and drive your digital transformation forward.</p>
      </div>
      {/* <div className="teamBx">
        <div className="member">
          <div className="imgBx">
            <img src={background1} />
          </div>
          <div className="details">
            <div>
              <h2 >John Doe<br /><span>Creative Director</span></h2>
            </div>
          </div>
        </div>
        <div className="member">
          <div className="imgBx">
            <img src={background2} />
          </div>
          <div className="details">
            <div>
              <h2>John Doe<br/><span>Creative Director</span></h2>
            </div>
          </div>
        </div>
        <div className="member">
          <div className="imgBx">
            <img src={background3}/>
          </div>
          <div className="details">
            <div>
              <h2>John Doe<br/><span>Creative Director</span></h2>
            </div>
          </div>
        </div>
       </div> */}
    </div>
    </section>
    );
}


export default Teams;
