import React from "react";
import './About.css'


const icon1 = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Microsoft_Azure_Logo.svg/2560px-Microsoft_Azure_Logo.svg.png";
const icon2 = "https://www.freecodecamp.org/news/content/images/2020/10/gcp.png";
const icon3 = "https://miro.medium.com/v2/resize:fit:1400/1*b_al7C5p26tbZG4sy-CWqw.png";
const 
About = () => {
    return(
    <div>
  <section className="sec about" >
    <div className="content" >
    <div className="mxw800p">
                <h3 className="SectionTitle" id="services">What we do</h3>
                <p className="SectionContent">
                "Welcome to our cloud project website! We specialize in providing reliable and scalable cloud solutions tailored to meet your specific business needs. Whether you require secure data storage, seamless application deployment, or efficient resource management, our cloud services offer the flexibility and power to drive your digital transformation. Our team of experienced professionals is dedicated to delivering cutting-edge technology, ensuring high availability, data integrity, and cost optimization. With our cloud solutions, you can unlock the full potential of the cloud, streamline your operations, and propel your business towards success in the digital era."
                </p>
    </div>
      <div className="services">
        <div className="box">
          <div className="iconBx">
            <img src={icon1} />
          </div>
          <div className="boxBoxContain">
            <h2 className="boxTitle">Azure</h2>
            <p className="boxContent">
Empower your business with Azure's comprehensive suite of cloud services, enabling seamless scalability, enhanced security, and accelerated innovation.</p>
          </div>
        </div>
        <div className="box">
          <div className="iconBx">
            <img src={icon2} />
          </div>
          <div class="boxBoxContain">
            <h2 className="boxTitle" >Google Cloud</h2>
            <p className="boxContent">
"Google Cloud empowers businesses with scalable and secure cloud services, enabling innovation, data-driven insights, and seamless collaboration for accelerated growth and digital transformation."</p>
          </div>
        </div>
        <div class="box">
          <div class="iconBx">
            <img src={icon3} />
          </div>
          <div class="boxBoxContain">
            <h2 className="boxTitle">Amazon Web Service</h2>
            <p className="boxContent">
"AWS: Empowering businesses with scalable, secure, and innovative cloud services for accelerated digital transformation."</p>
          </div>
        </div>
      </div>
    </div>
  </section>   
  <section className="sec stats">
    <div className="content">
      <div className="mxw800p">
        <h3 className="statsTitle">Our Achievements</h3>
        <p className="statsContent">Our achievements speak volumes about our expertise in cloud solutions. We have successfully helped numerous businesses migrate to the cloud, optimizing their operations, enhancing security, and achieving cost savings. Our team's in-depth knowledge and commitment to delivering excellence have earned us recognition as a trusted partner in enabling digital transformation through scalable infrastructure, robust data management, and seamless application deployment. Join our growing list of satisfied clients and experience the transformative power of our cloud solutions firsthand.</p>
      </div>
      <div className="statsBox">
        <div className="box">
          <h2>1200+</h2>
          <h4>Projects</h4>
        </div>
        <div className="box">
          <h2>800+</h2>
          <h4>Happy Clients</h4>
        </div>
        <div className="box">
          <h2>150+</h2>
          <h4>Countries</h4>
        </div>
        <div className="box">
          <h2>50+</h2>
          <h4>Awards</h4>
        </div>
      </div>
    </div>
  </section>
  </div>

 );

}

export default About;