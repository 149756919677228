import logo from './logo.svg';
import './App.css';
import Header from './component/Header/Header';
import Banner from './component/Banner/Banner';
import Section from './component/Section/Section';
import About from './component/About/About';
import Button from './component/Button/Button';
import React, { useEffect, useState } from 'react';
import Work from './component/Work/work';
import Footer from './component/Footer/Footer';
import Teams from './component/Teams/Teams';
import ButtonPermanant from './component/ButtonPermanant/ButtonPermanant';

const App = () => {
  const [showButton, setShowButton] = useState(false);
  const [shownavButton, setShownavButton] = useState(false);

  

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 250) {
        setShowButton(true);
        setShownavButton(true);
      } else {
        setShowButton(false);
        setShownavButton(false);


      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    console.log('Button clicked');
    // Perform any desired action here
  };
  

  return (
    <div className="container">
      <Header />
      <Banner />
      <Section />
      <About />
      {/* <Work /> */}
      <Teams />
      <Footer />
      {shownavButton && (<ButtonPermanant/>)}
                                                                                                             
    </div>                                                                                                                   
  );
}

export default App;
