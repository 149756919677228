// Sidebar.js

import React, { useState, useEffect } from 'react';
import "./Sidebar.css"
import { Link, animateScroll as scroll } from 'react-scroll';
import { AiOutlineClose } from 'react-icons/ai';



const Sidebar = ({ isOpen, onClose }) => {
  const [show, setShow] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMobileMenuToggle = (link) => {
    setMobileMenuOpen(!mobileMenuOpen);

  };
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <button className='ButtonClose' onClick={onClose}><AiOutlineClose /></button>
        <ul className='sidebarUl'>
        <li className ="sidebarLI">
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={handleMobileMenuToggle}>
            Home
          </Link>
        </li>
        <li className ="sidebarLI">
        <Link

          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={handleMobileMenuToggle}
          // className={activeLink === 'About' ? 'active' : ''}
        >
          About
        </Link>
      </li>
      <li className ="sidebarLI">
        <Link
          activeClass="active"
          to="services"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={handleMobileMenuToggle}
          // className={activeLink === 'Services' ? 'active' : ''}
        >
          Services
        </Link>
      </li>
      <li className ="sidebarLI">
        <Link
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={handleMobileMenuToggle}
          // className={activeLink === 'Contact' ? 'active' : ''}
        >
          Contact
        </Link>
      </li>
    
    
        </ul>
    </div>
  );
};

export default Sidebar;
