import React, { useState, useEffect } from 'react';
import './Header.css';
import { Link, animateScroll as scroll } from 'react-scroll';




const Header = () => {
  const [show, setShow] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
 // const [activeLink, setActiveLink] = useState('home');


 
  const controlNavbar = () => {
      if (window.scrollY > 250 ) {
          setShow(true)
      }else{
        setShow(false);


      }
  }

  useEffect(() => {
      window.addEventListener('scroll', controlNavbar)
      return () => {
          window.removeEventListener('scroll', controlNavbar)
      }
  }, [])

  

  const handleMobileMenuToggle = (link) => {
    setMobileMenuOpen(!mobileMenuOpen);

  };
  
  return (
    <div>
      <nav className="navbar">
        <div className="logo">SEGA</div>  
        <button className="mobile-menu-toggle" onClick={handleMobileMenuToggle}>
          {mobileMenuOpen ? 'Close' : 'Open'}
        </button>
        <ul className={`nav-links ${mobileMenuOpen ? 'show' : ''}`}>
        <li>
        <Link
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={handleMobileMenuToggle}>
          Home
        </Link>
      </li>
      <li>
        <Link
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={handleMobileMenuToggle}
          // className={activeLink === 'About' ? 'active' : ''}
        >
          About
        </Link>
      </li>
      <li>
        <Link
          activeClass="active"
          to="services"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={handleMobileMenuToggle}
          // className={activeLink === 'Services' ? 'active' : ''}
        >
          Services
        </Link>
      </li>
      <li>
        <Link
          activeClass="active"
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={handleMobileMenuToggle}
          // className={activeLink === 'Contact' ? 'active' : ''}
        >
          Contact
        </Link>
      </li>
    
        </ul>
      </nav>
      
   </div>
  );
};

export default Header;
