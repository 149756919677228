import React, { Component } from 'react'
import './Footer.css'

const Footer = () =>{
 
    return (
        <div >
    <section className="secContact" >
    <div className="content" id="contact">
      <div className="mxw800p">
        <h3 className='FooterTitle'>Send Us A Message</h3>
        <p className='FooterContent'>Send us a message and let us help you leverage the power of the cloud for your business success.</p>
      </div>
      <div class="conactForm">
        <form>
          <div class="row100">
            <div class="inputBx50">
              <input type="text" name="" placeholder="Full Name" />
            </div>
            <div class="inputBx50">
              <input type="text" name="" placeholder="Email Address" />
            </div>
          </div>
          <div class="row100">
            <div class="inputBx100">
              <textarea placeholder="Message"></textarea>
            </div>
          </div>
          <div class="row100">
            <div class="inputBx100">
              <input type="submit" value="Send" name=" "/>
            </div>
          </div>
        </form>
      </div>
   </div>
  </section>
 </div>
    );
}


export default Footer;
