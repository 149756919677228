import React from "react";
import './Section.css'

const Section = () => {
    return (

        <div className="sec about">
            <div className="content">
            <div className="mxw800p">
                <h3 className="SectionTitle">Who We Are</h3>
                <p className="SectionContent">Who We Are" is a compelling content creation agency dedicated to helping businesses effectively communicate their brand stories. With a team of skilled writers, strategists, and creatives, we craft engaging and impactful content that resonates with audiences across various platforms. From captivating website copy to persuasive blog articles, social media posts, and more, we work closely with our clients to understand their unique voice and objectives, delivering tailored content solutions that drive results. Our commitment to excellence and our passion for storytelling empower businesses to connect authentically with their target audience, build brand awareness, and foster long-lasting customer relationships.</p>
                <a href="#" className="btn">Read Our Story</a>
            </div>
         </div>
      </div>
    );

}
export default Section;